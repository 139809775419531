import { Box, CardMedia, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { FaUsers } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
import img1 from "../images/why-img-1.png";
import img2 from "../images/why-img-2.png";
import star from "../images/why-star.png";
import yellow from "../images/why-yellow.png";
import gray from "../images/why-gray.png";
import { FaCode } from "react-icons/fa";

export default function Why() {
  useEffect(() => {
    let sections = document.querySelector(".why");

    let numbers = document.querySelectorAll(".why .h2");

    let startcount = false;
    window.onscroll = function () {
      if (window.scrollY >= sections.offsetTop - 50) {
        if (startcount == false) {
          numbers.forEach((item) => startCounts(item));
        }

        startcount = true;
      }
    };

    function startCounts(element) {
      let goals = element.dataset.goal;
      console.log(goals);

      let counts = setInterval(() => {
        element.textContent++;
        if (element.textContent == goals) {
          clearInterval(counts);
        }
      }, 500 / goals);
    }
  });

  let blackBox = [
    {
      icon: <FaUsers style={{ color: "#1F4F76", fontSize: "50px" }} />,
      number: 120,
      text: "Satisficed ",
      text2: "Clients",
    },
    {
      icon: <FaCode style={{ color: "#1F4F76", fontSize: "50px" }} />,
      number: 200,
      text: "Projects ",
      text2: "Completed",
    },
    {
      icon: <FaUsers style={{ color: "#1F4F76", fontSize: "50px" }} />,
      number: 120,
      text: "Satisficed ",
      text2: "Clients",
    },
    {
      icon: <FaUsers style={{ color: "#1F4F76", fontSize: "50px" }} />,
      number: 120,
      text: "Satisficed ",
      text2: "Clients",
    },
  ];

  let ul = [
    {
      text: "Safe and error-free applications",
    },
    {
      text: "We understand that every business is unique , which is why we offer customized IT solutions designed to meet your specific needs..",
    },
    {
      text: "Multiple payment methods for everyone",
    },
    {
      text: "Affordable and competitive prices to the market and with higher quality",
    },
    {
      text: "Technical support for a period of six months after the delivery of the project",
    },
    {
      text: "All the domains, certificates, and maintenance you need in one place",
    },
  ];
  return (
    <Box
      className="why"
      sx={{ padding: { xs: "0px", md: "0px 30px" }, paddingTop: "0px" }}
      id="why-correct"
    >
      <Grid
        className="container"
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          marginBottom: "40px",
        }}
      >
        {blackBox.map((item, index) => {
          return (
            <Grid
              item
              sx={{
                padding: "0px 30px 5px",
                width: { xs: "100%", md: "auto" },
              }}
              className="black"
            >
              <Box
                className="box"
                sx={{
                  backgroundColor: "#2C2E3A",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "120px",
                  height: "100px",
                  margin: "0px auto",
                }}
              >
                <span>{item.icon}</span>
              </Box>
              <h2
                className="h2"
                style={{
                  color: "white",
                  fontSize: "30px",
                  textAlign: "center",
                  margin: "15px 0px",
                }}
                data-goal={item.number}
              >
                0
              </h2>
              <h3
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                {item.text}
              </h3>
              <h3
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                {item.text2}
              </h3>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", marginTop: "80px" }}
      >
        <Grid
          item
          xs={12}
          lg={5}
          sx={{
            padding: "20px",
          }}
        >
          <h1 style={{ color: "white", fontSize: "40px" }}>
            Why Our Company Stands Out <br /> other Companies ?
          </h1>
          <ul style={{ marginBottom: "30px" }}>
            {ul.map((item, index) => {
              return (
                <li
                  style={{
                    color: "rgba(255,255,255,0.8)",
                    display: "flex",
                    gap: "20px",
                    fontSize: "20px",
                    alignItems: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <Box>
                    <FaCircle style={{ fontSize: "20px" }} />{" "}
                  </Box>
                  <Box>
                    <span>{item.text}</span>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                gap: "10px",
                padding: "30px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40px",
                  display: { xs: "none", md: "flex" },
                }}
              >
                <img src={yellow} alt="..." />
                <img src={gray} alt="..." />
                <img src={gray} alt="..." />
              </Box>
              <Box>
                <img src={img1} alt="..." style={{ width: "100%" }} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              transform: { xs: "translateY(0px)", md: "translateY(-90px)" },
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                height: "auto",
                alignItems: "flex-end",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Box>
                <CardMedia
                  image={img2}
                  component="img"
                  sx={{ margin: "auto", width: { xs: "85vw", md: "100%" } }}
                />
              </Box>

              <Box
                sx={{
                  backgroundColor: "#171717",
                  padding: { xs: "15px 20px ", md: "10px 20px" },
                  width: { xs: "100%", md: "160px" },
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: { xs: "auto", md: "185px" },
                  marginLeft: "-5px",
                  marginBottom: { xs: "0", md: "5px" },
                  marginTop: { xs: "30px", md: "0px" },
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    padding: "10px 20px",
                    backgroundColor: "#2C2E3A",
                  }}
                >
                  <img
                    src={star}
                    alt="..."
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </span>
                <h3
                  style={{
                    fontSize: "35px",
                    color: "white",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  +3
                </h3>
                <h5
                  style={{
                    fontSize: "18px",
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  Years of <br /> experience
                </h5>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
