import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AllServices from "./pages/AllServices";
import WebDevelopment from "./pages/WebDevelopment";
import MobileApp from "./pages/MobileApp";
import CyberSecurity from "./pages/CyberSecurity";
import SSL from "./pages/SSL";
import UIUX from "./pages/UIUX";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="services" element={<AllServices />} />
      <Route path="web-development" element={<WebDevelopment />} />
      <Route path="mobile-app" element={<MobileApp />} />
      <Route path="cyber-security" element={<CyberSecurity />} />
      <Route path="ssl" element={<SSL />} />
      <Route path="ui-ux" element={<UIUX />} />





    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

