import { Box, Grid } from '@mui/material'
import React from 'react'
import TopShared from './components/TopShared'
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

import img from "../images/contact.png"
export default function Contact() {
    let heading = "Contact"
    let page = "CONTACT"

    let leftData = [
        { icon: <FaPhoneVolume />, p: "Have any question?", h1Before: "Free", h1: "0222877709" },
        { icon: <IoMdMail />, p: "Send Email", h1: "info@correct.ws" },
        { icon: <FaLocationDot />, p: "Visit Anytime", h1Before: "6391 Elgin St. Delaware" },


    ]
    return (
        <Box>

            <TopShared heading={heading} page={page} />



            <Grid className='contact-container' container sx={{ display: "flex", justifyContent: "center", width: {xs:"90vw" ,md: "80vw"}, margin: "auto" , transform:"translateY(100px)" }}>

                <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column", gap: "15px"  }}>

                    {
                        leftData.map((item, index) => {
                            return (

                                <Box sx={{
                                    backgroundImage: "linear-gradient(#1f4f76, #0c2233)",
                                    padding: "30px 20px " , display:"flex", alignItems:"center" , gap:"15px" , 

                                }}>
                                    <Box><span className='contact-icon'>{item.icon}</span></Box>
                                    <Box sx={{display:"flex" , flexDirection:"column" , gap:"5px" , color:"white"}}>
                                        <p>{item.p}</p>
                                        <p style={{fontSize:"20px" , fontWeight:"500"}}>{item.h1Before ? <span>{item.h1Before}</span> : undefined}  <span>{item.h1}</span></p>
                                    </Box>
                                </Box>

                            )
                        })
                    }

                    <img src={img} alt="..."  style={{marginTop:"-15px" , maxHeight:"385px"}}/>

                </Grid>
                <Grid item xs={12} md={8} sx={{padding:"30px" , display:"flex" , flexDirection:"column"}}>
                    <p className='contact-p' >CONTACT WITH US</p>
                    <h1 style={{marginBottom:"40px" , fontSize:"40px"}}> Feel Free to Write us Anytime</h1>
                    <form


className='form'
                    



                    >

                        <Grid container sx={{ display:"flex" , flexDirection:"column"  , gap:"30px" ,  flexWrap:"wrap"}}>
                            <Grid item  sx={{display:"flex" , gap:"20px" , flexWrap:"wrap" }} >
                                <input  style={{flexGrow:"1" , padding:"20px", color:"#ccc" , outline:"none" , border:"none" , border:"2px solid #f2f2f2"}}  type="text" placeholder='Your name' />
                                <input  style={{flexGrow:"1" , padding:"20px", color:"#ccc" , outline:"none" , border:"none" , border:"2px solid #f2f2f2"}}  type="email" placeholder='Email address' />

                            </Grid>
                            <Grid item sx={{display:"flex" , gap:"20px" ,  flexWrap:"wrap" }}>
                                <input type="text" style={{flexGrow:"1" , padding:"20px", color:"#ccc" , outline:"none" , border:"none" , border:"2px solid #f2f2f2"}} placeholder='Phone' />
                                <input type="text"style={{flexGrow:"1" , padding:"20px", color:"#ccc" , outline:"none" , border:"none" , border:"2px solid #f2f2f2"}} placeholder='Subject' />

                            </Grid>
                            <textarea placeholder='Write a message' style={{minHeight:"250px" ,padding:"20px" , border:"none" , border:"2px solid #f2f2f2", outline:"nonr" }}></textarea>
                            <input type="submit" value="Send a Message" style={{ backgroundImage: "linear-gradient(#1f4f76, #0c2233)", color:"white", fontSize:"15px",border:"none", marginTop:"20px",width:"200px" , padding:"25px" , cursor:"pointer" , display:"block"}} />
                        </Grid>

                    </form>
                </Grid>
            </Grid>





            <iframe

                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3453.850420383605!2d31.342571324542536!3d30.041148874925334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583e8f20fcc56d%3A0x949de2cec2d23806!2zMzMg2KfZhNiv2YPYqtmI2LEg2KXYqNix2KfZh9mK2YUg2KPYqNmIINin2YTZhtis2KfYjCDYp9mE2K3Yr9mK2YLYqSDYp9mE2K_ZiNmE2YrYqdiMINmF2K_ZitmG2Kkg2YbYtdix2Iwg2YXYrdin2YHYuNipINin2YTZgtin2YfYsdip4oCsIDQ0NDEyODA!5e0!3m2!1sar!2seg!4v1719354581810!5m2!1sar!2seg"
                width={600}
                height={450}
                style={{ border: 0, width: "100%", height: "65vh" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            />


        </Box>
    )
}
