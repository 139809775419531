import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";

import img1 from "../images/web.png";
import img2 from "../images/mobile-service.png";
import img3 from "../images/ui.png";
import img4 from "../images/service-4.png";
import vec from "../images/service-vec.png";
import topVec from "../images/Vector 51.png";
import topVec2 from "../images/download2.svg";
import topVec3 from "../images/Vector 52.png";
import { FaLaptopCode } from "react-icons/fa6";
import { FaCode } from "react-icons/fa";
import { FaMobileScreen } from "react-icons/fa6";
import TopShared from "./components/TopShared";
import { useNavigate } from "react-router-dom";
import { MdOutlineDesignServices } from "react-icons/md";
export default function Services() {
  let navigate = useNavigate()

  let data = [
    {
      text: "Web Development",
      img: img1,
      path: "/web-development",
      p:"Elevate your online presence with our comprehensive web development services.",
      icon: <FaLaptopCode style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>
    },
    {
      text: "Mobile Application",
      text2: "Security",
      img: img2,
      path: "/mobile-app",
      p:"mobile application is essential for businesses.",
      icon: <FaMobileScreen style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>


    },
    {
      text: "UI / UX",
      img: img3,
      path: "/ui-ux",
      p:"Transform your digital presence with our comprehensive UI/UX design services.",
      icon: <MdOutlineDesignServices style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>


    },
    // {
    //   text: "SSL",
    //   img: img4,
    //   path :"/ssl"
    // },
  ];
  return (
    <Box className="service" id="services" sx={{ marginTop: "0px" }}>

      <h1>Services</h1>
      <h2 style={{ fontSize: "40px", textAlign: "center", margin: "40px" }}>
        Services We’re Providing to <br /> Our Customers
      </h2>
      <Grid
        container
        sx={{ display: "flex", gap: "20px", justifyContent: "center", gap: "20px", padding: "20px" }}
      >
        {data.map((item, index) => {
          return (
            <Grid
              data-aos="fade-in"
              className="img-item"
              xs={12} sm={4.5} md={4} lg={2.4}
              onClick={() => {

                navigate(item.path)
                window.scrollTo({
                  top: 0,
                  behavior: "smooth"
                })
              }}
              item
              sx={{
                backgroundColor: "#1F4F76",
                overflow: "hidden",
                position: "relative",

                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                cursor: "pointer"
              }}
              key={index}
            >

              <Box className="topVec">
                <img src={topVec} alt="..." className="img1" />
                <img src={topVec3} alt="..." className="img2" />

                {item.icon}{" "}
                {/* <img src={topVec2} alt="..."  className="small" /> */}

              </Box>
              <Box>
                <img src={item.img} alt="..." className="standard"  />
              </Box>
              <Box className="service-layer">
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "22px",
                    margin: "30px 0px 10px",
                    padding: "20px 0px 0px",
                    display: "flex",
                    fontWeight: "500",
                    flexDirection: "column",
                  }}
                >
                  <span> {item.text}</span>

                  {item.text2 ? <span> {item.text2}</span> : undefined}
                </p>



                <p className="serve-p">{item.p}

                </p>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <a
        href="/services"
        style={{

          color: "white",
          display: "inline-block",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: "40px",
          marginBottom: "60px",
          padding: "10px 20px",
          textDecoration: "none",
        }}
        className="a"
        onClick={() => {
          window.scrollTo({

            top: "0",
            behavior: "smooth"
          })
        }}
      >
        Discover More
      </a>
    </Box>
  );
}
