import { Box, CardMedia, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopShared from "../components/TopShared";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaCirclePlus } from "react-icons/fa6";
import { FaCircleMinus } from "react-icons/fa6";
import { GiSettingsKnobs } from "react-icons/gi";
import { FaBrain } from "react-icons/fa6";
import boy from "../../images/boy.png";
import img from "../../images/web (2).png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import poly from "../../images/Polygon 1.png";
import man from "../../images/man.png";

export default function ServiceProp(props) {
  let navigate = useNavigate();
  let [web, setweb] = useState(false);
  let [app, setApp] = useState(false);
  let [cyber, setCyber] = useState(false);
  let [ssl, setSSL] = useState(false);
  let [ui, setUI] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/web-development") {
      setweb(true);
    }

    if (window.location.pathname === "/mobile-app") {
      setApp(true);
    }
    if (window.location.pathname === "/ui-ux") {
      setUI(true);
    }
    if (window.location.pathname === "/ssl") {
      setSSL(true);
    }
    if (window.location.pathname === "/cyber-security") {
      setCyber(true);
    }
  }, []);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange2 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className="serve">
      <TopShared heading={props.heading} page={props.page} />
      <Grid
        container
        sx={{
          width: {
            xs: "95vw",
            md: "85vw",
            margin: "100px auto",
            display: "flex",
            justifyContent: "center",
            gap: "30px",
          },
        }}
      >
        <Grid
          item
          xs={12}
          md={4.5}
          lg={3}
          className="feilds-item"
          sx={{ height: "auto" }}
        >
          <h1 style={{ padding: "20px" }}>{props.allData.h1}</h1>
          <Box className="feilds">
            <p
              className="feild"
              onClick={() => {
                navigate("/web-development");
              }}
            >
              <span>{props.allData.pspan1}</span> <IoIosArrowForward />{" "}
              <p
                style={{ left: web ? "0px" : undefined, transition: "0.2s" }}
                className="layer"
                id="layer"
              >
                {props.allData.pspan1}
              </p>
            </p>
            <p
              className="feild"
              onClick={() => {
                navigate("/mobile-app");
              }}
            >
              <span>{props.allData.pspan2}</span> <IoIosArrowForward />
              <p
                className="layer"
                id="layer"
                style={{ left: app ? "0px" : undefined, transition: "0.2s" }}
              >
                {props.allData.pspan2}
              </p>
            </p>
            <p
              className="feild"
              onClick={() => {
                navigate("/ui-ux");
              }}
            >
              <span>{props.allData.pspan3}</span> <IoIosArrowForward />
              <p
                className="layer"
                id="layer"
                style={{ left: ui ? "0px" : undefined, transition: "0.2s" }}
              >
                {props.allData.pspan3}
              </p>
            </p>
            <p
              className="feild"
              onClick={() => {
                navigate("/cyber-security");
              }}
            >
              <span>{props.allData.pspan4}</span> <IoIosArrowForward />
              <p
                className="layer"
                id="layer"
                style={{ left: cyber ? "0px" : undefined, transition: "0.2s" }}
              >
                {props.allData.pspan4}
              </p>
            </p>
            <p
              className="feild"
              onClick={() => {
                navigate("/ssl");
              }}
            >
              <span>{props.allData.pspan5}</span> <IoIosArrowForward />
              <p
                className="layer"
                id="layer"
                style={{ left: ssl ? "0px" : undefined, transition: "0.2s" }}
              >
                {props.allData.pspan5}
              </p>
            </p>
          </Box>

          <Box
            sx={{
              marginTop: "30px",
              backgroundColor: "#232429",
              color: "white",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: "30px 0px 0px 20px",
              position: "relative",
            }}
          >
            <h2
              style={{
                fontSize: "30px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {props.allData.getFree} <br /> {props.allData.solution} <br />{" "}
              {props.allData.it}
            </h2>

            <Link
              to="/contact"
              className="contact-link"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              {props.allData.contact}
            </Link>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <img src={poly} alt="..." style={{ width: "150px" }} />
              <img
                src={man}
                alt="..."
                style={{ position: "absolute", bottom: "0px" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box>
            <img
              src={props.allData.bigImage}
              alt="..."
              style={{ width: "100%", marginBottom: "30px",maxHeight:"555.8px" }}
            />
          </Box>

          <h1 style={{ color: "#222429", marginBottom: "20px" }}>
            {props.allData.product}
          </h1>

          <p style={{ color: "#686a6f", fontSize: "16px", lineHeight: "28px" }}>
            {props.allData.p1}
          </p>
          {/* <h1 style={{ color: " #1f4f76", margin: "20px 0px" }}>
            {props.allData.h11}
          </h1> */}
          <p style={{ color: "#686a6f", fontSize: "16px", lineHeight: "28px" }}>
            {props.allData.p2}
          </p>
          <h1
            style={{
              color: "#222429",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          >
            {props.allData.h22}
          </h1>

          <Grid
            container
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: { xs: "center", md: "space-between" },
              marginBottom: "20px",
            }}
          >
            <Grid item xs={12} lg={5.8} className="item">
              <Box className="icon">
                <img
                  src={props.allData.icon11}
                  alt="..."
                  style={{ width: "40px" }}
                />
              </Box>
              <Box>
                <p className="h1"> {props.allData.service}</p>
                <p className="p">{props.allData.lorem1}</p>
              </Box>
            </Grid>

            <Grid item xs={12} lg={5.8} className="item">
              <Box className="icon">
                <img
                  src={props.allData.icon22}
                  alt="..."
                  style={{ width: "40px" }}
                />
              </Box>
              <Box>
                <p className="h1"> {props.allData.IT}</p>
                <p className="p">{props.allData.lorem2}</p>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              margin: "30px 0px",
            }}
          >
            <Grid item xs={12}>
              <CardMedia
                image={props.allData.smallImage}
                component="img"
                sx={{ width: { xs: "100%", md: "350px" } }}
              />
            </Grid>

            <Grid item>
              <h1
                className="h1"
                style={{ padding: "20px 0px", fontWeight: "550" }}
              >
                {props.allData.ourfew}
              </h1>
              {/* <p className="p" style={{ margin: "0px", margin: "30px 0px" }}>
                {props.allData.fewP}
              </p> */}
              {/* <h1 className="h1">{props.allData.id}</h1> */}
              <ul
                style={{
                  listStyleType: "none",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <li
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "15px",
                    lineHeight: "25px",
                  }}
                >
                  <Box>
                    <FaCircleCheck
                      style={{ color: "276393", fontSize: "22px" }}
                    />{" "}
                  </Box>
                  <Box>
                    <span> {props.allData.id2}</span>
                  </Box>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "flex-start",

                    gap: "15px",
                    lineHeight: "25px",
                  }}
                >
                  <Box>
                    <FaCircleCheck
                      style={{ color: "276393", fontSize: "22px" }}
                    />{" "}
                  </Box>
                  <Box>
                    <span> {props.allData.id3}</span>
                  </Box>
                </li>
                <li
                  style={{
                    display: "flex",
                    alignItems: "flex-start",

                    gap: "15px",
                    lineHeight: "25px",
                  }}
                >
                  <Box>
                    <FaCircleCheck
                      style={{ color: "276393", fontSize: "22px" }}
                    />{" "}
                  </Box>
                  <Box>
                    <span>{props.allData.id4}</span>
                  </Box>
                </li>
              </ul>
            </Grid>
          </Grid>

          <Box
            className="accordion"
            sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Accordion
              sx={{
                boxShadow: "0px 0px 0px transparent , 0px 0px 0px transparent",
                border: "none",
                border: "2px solid #f2f2f2",
                padding: "15px",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ textWrap: "wrap" }}
              >
                <h3
                  sx={{
                    flexShrink: 0,
                    fontSize: "18px",
                    fontWeight: "700",
                    textWrap: "wrap",
                  }}
                >
                  {props.allData.title1}
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#686a6f",
                    lineHeight: "25px",
                  }}
                >
                  {props.allData.details1}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="acc"
              sx={{
                boxShadow: "0px 0px 0px transparent , 0px 0px 0px transparent",
                border: "none",
                border: "2px solid #f2f2f2",
                padding: "15px",
              }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{ textWrap: "wrap" }}
              >
                <h3
                  sx={{
                    flexShrink: 0,
                    fontSize: "18px",
                    lineHeight: "25px",
                    fontWeight: "700",
                    textWrap: "wrap",
                  }}
                >
                  {props.allData.title2}
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#686a6f",
                    lineHeight: "25px",
                  }}
                >
                  {props.allData.details2}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          {window.location.pathname == "/cyber-security" ? (
            <a
              className="contact-link"
              href="https://correctsoc.com/"
              style={{ marginTop: "20px" }}
              data-aos-anchor-placement="center-center"
              onClick={() => {
                navigate("/contact");
              }}
            >
              CorrectSOC
            </a>
          ) : undefined}
        </Grid>
      </Grid>
    </Box>
  );
}
