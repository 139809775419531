import { Box } from '@mui/material'
import React from 'react'

export default function TopShared(props) {
    return (
        <Box className="top-shared" >

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", transform: "translateY(50%)" }}>
                <h1 style={{ textAlign: "center", fontSize: "50px" }}>{props.heading}</h1>
                <p style={{ textAlign: "center", fontSize: "15px", fontSize: "500" }}><span>CORRECT</span> <span>/</span>  <span>{props.page}</span></p>
            </Box>

        </Box>
    )
}
