import { Box, CardMedia, Grid } from "@mui/material";
import React, { useState } from "react";
import logo from "../../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { MdPhoneInTalk } from "react-icons/md";
import { FaBars } from "react-icons/fa";
import Drawer from "./Drawer";
import { HashLink } from "react-router-hash-link";

export default function Header() {
  let navigate = useNavigate();
  let [left, setLeft] = useState(false);
  let links = [
    { text: "Home", path: "/" },
    { text: "About", path: "/#about" },
    { text: "Services", path: "/#services" },
    { text: "Why Correct", path: "/#why-correct" },
    { text: "Contact", path: "contact" },
  ];
  let [drawer, setDrawer] = useState(false);

  return (
    <Box
      className="header"
      sx={{
        position: "absolute",
        top: "0px",
        zIndex: "100",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        borderBottom: "1px solid rgba(255,255,255,0.6)",
      }}
    >
      <Drawer drawer={drawer} setDrawer={setDrawer} setLeft={setLeft} left={left}/>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={6}
          md={2}
          sx={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            navigate("/");
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <CardMedia
            image={logo}
            component="img"
            sx={{ width: { xs: "auto", lg: "155px" } }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            gap: "30px",
          }}
        >
          {links.map((item, index) => {
            return (
              <HashLink
              className="nav-link"
                to={item.path}
                style={{
                  color: "white",
                  fontSize: "18px",
                  textDecoration: "none",
                  fontWeight: "400",
                }}
                smooth={true}
                duration={500}
              >
                {item.text}
              </HashLink>
            );
          })}
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MdPhoneInTalk
              style={{
                backgroundColor: "#1F4F76",
                color: "white",
                fontSize: "40px",
                borderRadius: "100%",
                padding: "10px",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <p style={{ color: "#999b9f", fontSize: "14px" }}>Call to Anyone</p>
            <p style={{ color: "white", fontSize: "20px", fontWeight: "500" }}>
            0222877709

            </p>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          onClick={() => {
            setDrawer(true);


            setTimeout(()=>{

              setLeft(true)
            } , 500)
          }}
        >
          <FaBars
            style={{ color: "white", fontSize: "25px", cursor: "pointer" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
