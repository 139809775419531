import React from 'react'

import stars from "../../images/stars.png"
import { Box, CardMedia, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { GoTriangleRight } from "react-icons/go";
import WhatSlider from './WhatSlider';
import logo1 from "../../images/drop.png";
import logo2 from "../../images/micro.png";
import logo3 from "../../images/strip.png";
import logo4 from "../../images/wise.png";
import logo5 from "../../images/we.png";
import logo6 from "../../images/pand.png";
export default function WhatSaid() {
  let images = [
    { img: logo1 },
    { img: logo2 },
    { img: logo3 },
    { img: logo4 },
    { img: logo5 },
    { img: logo6 },
  ];

  return (
    <Box className="what-said" >

     <Box  >
     <Grid container sx={{ position:"relative"  , margin:"50px auto" , display:"flex" , justifyContent:"center" , alignItems:"center"}} className='what-container' >
          <Box className="box-1"></Box>
          <Box className="box-2"></Box>

            <Grid item xs = {11} sm={10} md={9}>
                <WhatSlider/>
            </Grid>
        </Grid>

     </Box>
      <Box sx={{width:"200vw", display:"flex" , overflow:"hidden", gap:"50px" }} className="linear">
      <Box

        sx={{
          display: "flex",
          justifyContent: "space-between",
     
          width:{xs:"450vw", md:"100vw"},
          gap:"30px"
        }}
        className="index-logos left-animate"
      >
        {images.map((item, index) => {
          return <img src={item.img} alt="..." style={{ width: "150px" }} />;
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width:{xs:"450vw", md:"100vw"},
          gap:"30px"


        }}
        className="index-logos left-animate"
      >
        {images.map((item, index) => {
          return <img src={item.img} alt="..." style={{ width: "150px" }} />;
        })}
      </Box>
      </Box>
    </Box>
  )
}
