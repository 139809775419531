import { Box, CardMedia, Grid } from "@mui/material";
import React, { useEffect } from "react";
import img from "../images/consult-img.png";

export default function Consult() {
  

  return (
    <Box className="consult" >
      <Grid container sx={{display:"flex", alignItems:"center" ,justifyContent:"center" , padding:"20px" , paddingBottom:"0px"}}>
        <Grid item xs={12} md={4} >
          <CardMedia image={img} component="img"  sx={{maxWidth:{xs:"280px", md :"400px"}}}/>
        </Grid>
        <Grid item xs={12} md={6}  sx={{padding:"20px 10px"}}>
          <h1 style={{fontSize:"55px" , color:"white"}}>
            Consult Your <br/> Business with <span style={{color:"#1F4F76" , display:"inline-block" , margin:"0px 10px"}}>Correct</span><br/>
            IT Solutions
          </h1>
          <p style={{color:"rgba(255,255,255,0.8)", fontSize:"20px", margin:"30px 0px"}}>
            At Correct, we are committed to providing top-notch IT solutions and
            exceptional customer service. Whether you have questions about our
            services, need technical support, or want to discuss a new project,
            our team is here to assist you.
          </p>
          <a href="/contact"   onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }} className="a" style={{ color:"white", display:"inline-block", padding:"10px 20px", textDecoration:"none"}}>Contact Us</a>
        </Grid>
      </Grid>
    </Box>
  );
}
