import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";

import img from "../../images/footer-img.png";
import { FaPhone } from "react-icons/fa6";
import { MdLanguage } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import logo from "../../images/logo.png";
import twit from "../../images/twitter.png";
import inst from "../../images/inst.png";
import facebook from "../../images/facebook.png";
import earth from "../../images/earth-footer.png";
import network from "../../images/footer-shape.png";

import { Link, useNavigate } from "react-router-dom";

export default function Footer() {
  let navigate = useNavigate();

  let style = {
    GridContainer: {
      backgroundColor: "rgba(0,0,0,0.1)",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      padding: "20px",
      width: "80vw",
      margin: "0px auto 80px",
    },
    GridItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "5px",
    },
    p: {
      color: "#898E90",
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: "500",
    },
  };
  let links = [
    { text: "Home", path: "/" },
    { text: "About", path: "/#about" },
    { text: "Services", path: "/#services" },
    { text: "Why Correct", path: "/#why-correct" },
    { text: "Contact", path: "contact" },
  ];
  return (
    <Box className="footer">
      <Box className="lay">
        <img src={earth} alt="..." className="img1" />
      </Box>
      <img src={network} alt="..." className="img2" />

      <Grid container sx={style.GridContainer} className="container">
        <Grid item xs={12} md={3} sx={style.GridItem}>
          <img src={logo} alt="..." />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            margin: { xs: "20px 0px" },
            borderBottom: "1px solid rgba(255,255,255,0.2)",
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          <img src={inst} alt="..." />
          <img src={twit} alt="..." />
          <img src={facebook} alt="..." />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          padding: "20px",
        }}
      >
        <Grid item xs={12} sm={5.5} lg={3}>
          <h1
            style={{ color: "white", fontSize: "18px", marginBottom: "25px" }}
          >
            ABOUT
          </h1>
          <p style={style.p}>
            We are team of developers and designers work together with the
            experience, efficiency and creativity in programming and design, to
            presenting our business differently around the world.
          </p>

          <form
            style={{
              display: "flex",
              alignItems: "center",
              border: "0px",
              marginTop: "30px",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <input
              type="email"
              placeholder="Email"
              style={{
                backgroundColor: "black",
                borderRadius: "0px",
                color: "#898E90",
                padding: "20px ",
                fontSize: "18px",
                border: "none",
                outline: "none",
              }}
            />
            <Button
              sx={{
                backgroundColor: "#FFC444",
                padding: "20px ",
                height: "100%",
                borderRadius: "0px",
                fontSize: "20px",
                color: "black",
                width: "30px",
                "&:hover": {
                  backgroundColor: "#FFC444",
                },
              }}
            >
              <FaArrowRight />
            </Button>
          </form>
        </Grid>
        <Grid item xs={12} sm={5.5} lg={3}>
          <h1
            style={{ color: "white", fontSize: "18px", marginBottom: "25px" }}
          >
            LINKS
          </h1>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            {links.map((item, index) => {
              return (
                <Link
                  style={{
                    color: "#898E90",
                    fontSize: "16px",
                    fontWeight: "500",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  className="links"
                  key={index}
                  to={item.path}
                  onClick={() => {
                    navigate(item.path);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <span> {item.text}</span>
                </Link>
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} sm={5.5} lg={3.2} sx={{ backgroundColor: "rgba(0,0,0,0.1)" }}>
          <h1 style={{ color: "white", fontSize: "18px", padding: "20px" }}>
            CONTACT
          </h1>

          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              padding: " 20px",
            }}
          >
            <li style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <FaPhone
                style={{
                  color: "#FFC444",
                  fontSize: "34px",
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#232429",
                }}
              />{" "}
              <span style={{ color: "#67696A", fontSize: "20px" }}>
                0222877709
              </span>
            </li>
            <li style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <MdLanguage
                style={{
                  color: "#FFC444",
                  fontSize: "34px",
                  padding: "10px",
                  borderRadius: "100%",
                  backgroundColor: "#232429",
                }}
              />{" "}
              <span style={{ color: "#67696A", fontSize: "20px" }}>
                info@correct.ws
              </span>
            </li>
            <li
              style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <FaLocationDot
                  style={{
                    color: "#FFC444",
                    fontSize: "34px",
                    padding: "10px",
                    borderRadius: "100%",
                    backgroundColor: "#232429",
                  }}
                />{" "}
              </Box>

              <Box>
                <p
                  style={{
                    color: "#67696A",
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <span>
                    Egypt Cairo 33 Dr. Ibrahim Abu Al-Naga Street, Seventh
                    District, Nasr City Second floor
                  </span>{" "}
                  <span>
                    Saudi Arabia Riyadh Murabba Commercial Complex Office No. 88
                  </span>
                </p>
              </Box>
            </li>
          </ul>
          {/* <img src={img} alt="..." style={{ width: "100%" }} /> */}
        </Grid>
      </Grid>
      <p
        style={{
          color: "#999B9F",
          textAlign: "center",
          padding: "35px 20px",
          backgroundColor: "black",
          fontWeight: "500",
          position: "relative",
          zIndex: "100",
        }}
      >
        @ 2018 - 2024 Correct Development LTD All rights reserved.
      </p>
    </Box>
  );
}
