import { Box, Grid } from '@mui/material'
import React from 'react'

import star from "../../images/star.png"

export default function LineAnimate() {

    let data = [
        { text: "Web Development" },
        { text: "Mobile Application" },
        { text: "digital Marketing" },
        { text: "Cyber Security" },
        { text: "SSL" },
    ]
    return (
        <Box sx={{ margin: "120px 0px", backgroundColor: "#FFC444" , position:"relative" ,overflow: "hidden" , display:"flex" , width:"200vw" , gap:"40px" }} className="linear">


            <Grid className='left-animate' container sx={{width:{xs:"450vw",md:"100vw"},flexWrap:"nowrap", display: "flex", justifyContent: "space-between", gap: "10px", backgroundColor: "#FFC444", transform: "rotate(-4deg)" , padding:"35px" }}>
                {
                    data.map((item, index) => {

                        return (

                            <Grid className='slide' item  sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <img src={star} alt="..." className='star' />
                                <span>{item.text}</span>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid className='left-animate' container sx={{width:{xs:"450vw",md:"100vw"},flexWrap:"nowrap",overflow: "hidden",display: "flex", justifyContent: "space-between", gap: "10px", backgroundColor: "#FFC444", transform: "rotate(-4deg)" , padding:"35px" }}>
                {
                    data.map((item, index) => {

                        return (

                            <Grid className='slide' item  sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                <img src={star} alt="..." className='star' />
                                <span>{item.text}</span>
                            </Grid>
                        )
                    })
                }
            </Grid>


        </Box>
    )
}
