import { Box } from "@mui/material";
import React, { useState } from "react";
import { FaStar } from "react-icons/fa";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { Keyboard, Navigation, Pagination } from "swiper/modules";

import person1 from "../../images/person1.png";
import person2 from "../../images/person2.png";
import person3 from "../../images/person3.png";

function WhatSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    {
     
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"
    },
    {
   
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"

    },
    {
    
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"

    },

    {
    
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"

    },
    {
     
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"

    },
    {
      
      p: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words There are many variations of passages of Lorem Ipsum available, but the majority",
      name: "Theresa Webb                                ",
      type:"customer"

    },
  ];

  const handlePrevClick = () => {
    const firstIndex = currentIndex === 0;
    setCurrentIndex(firstIndex ? slides.length - 1 : currentIndex - 1);
  };

  const handleNextClick = () => {
    const lastIndex = currentIndex === slides.length - 1;
    setCurrentIndex(lastIndex ? 0 : currentIndex + 1);
  };
  return (
    <Box className="what" id="what">
      <Box>
        <Swiper
          style={{ padding: "100px 0px 150px" }}
          breakpoints={{
            1200: {
              slidesPerView: 1, // Number of slides per view for extra large screens (1200px and above)
            },
            992: {
              slidesPerView: 1, // Number of slides per view for large screens (992px to 1199px)
            },
            768: {
              slidesPerView: 1, // Number of slides per view for medium screens (768px to 991px)
            },
            576: {
              slidesPerView: 1, // Number of slides per view for small screens (576px to 767px)
            },
          }}
          slidesPerGroupSkip={1}
          grabCursor={true}
          spaceBetween={10}
          navigation={true}
          modules={[Keyboard, Navigation, Pagination]}
          className="mySwiper"
        >
          {slides.map((item, index) => {
            return (
              <SwiperSlide key={index} className="slide "   >
            <FormatQuoteIcon style={{fontSize:"100px",padding:"20px", position:"absolute", top:"0px", transform:"translateY(-50%)" , backgroundColor:"#ffc444" , borderRadius:"100%"}}/>
            <Box sx={{display:"flex" , gap:"10px"}}>
                  <FaStar style={{color:"#ffc444", fontSize:"26px"}}/>
                  <FaStar style={{color:"#ffc444", fontSize:"26px"}}/>
                  <FaStar style={{color:"#ffc444", fontSize:"26px"}}/>
                  <FaStar style={{color:"#ffc444", fontSize:"26px"}}/>
                  <FaStar style={{color:"#ffc444", fontSize:"26px"}}/>

                </Box>
                  <Box
                    sx={{
                      // padding: "30px 10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <p style={{fontSize:"22px", fontWeight:"500" ,width:"94%", margin:"auto", color:"#999b9f" , textAlign:"center" , padding:"20px" , lineHeight:"30px"}}>{item.p}</p>
                    <p style={{fontWeight:"600", color:"#686a6f" , textAlign:"center" , margin:"5px 0px"}}>{item.name}</p>
                    <p style={{textAlign:"center", color:"#999b9f" , fontSize:"18px" , marginBottom:"20px"}}>{item.type}</p>
                  </Box>
               
        
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Box>
    </Box>
  );
}

export default WhatSlider;
