import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import topVec from "../images/Vector 51.png";
import { FaLaptopCode } from "react-icons/fa6";
import { FaCode } from "react-icons/fa";
import { FaMobileScreen } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { MdOutlineDesignServices } from "react-icons/md";
import { MdOutlineSecurity } from "react-icons/md";
import { FaExpeditedssl } from "react-icons/fa";

import img4 from "../images/cyber-security-image.png";
import vec from "../images/service-vec.png";
import TopShared from "./components/TopShared";

// import { FaCode } from "react-icons/fa";
import topVec2 from "../images/download2.svg";
import topVec3 from "../images/Vector 52.png";
import img1 from "../images/web.png";
import img2 from "../images/mobile-service.png";
import img3 from "../images/ui.png";

import img5 from "../images/out-ssl.jpg";


export default function AllServices() {
  let navigate = useNavigate();
  let data = [
    {
      text: "Web Development",
      img: img1,
      path: "/web-development",
      p:"Elevate your online presence with our comprehensive web development services.",
      icon: <FaLaptopCode style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>
    },
    {
      text: "Mobile Application",
      text2: "Security",
      img: img2,
      path: "/cyber-security",
      p:"mobile application is essential for businesses.",
      icon: <FaMobileScreen style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>


    },
    {
      text: "UI / UX",
      img: img3,
      path: "/ui-ux",
      p:"Transform your digital presence with our comprehensive UI/UX design services.",
      icon: <MdOutlineDesignServices style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>


    },
    {
      text: "Cyber Security",
      img: img4,
      path: "/cyber-security",
      p: "Secure your business with our comprehensive cybersecurity services.",
      icon: <MdOutlineSecurity style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>

    },
    {
      text: "SSL",
      img: img5,
      path: "/ssl",
      p:"Secure Your Website with SSL Certificates In today's digital landscape.",
      icon: <FaExpeditedssl style={{ fontSize: "50px", transform: "translate(-220%, -20%)" }}/>

    },
  ];
  let heading = "Services";
  let page = "SERVICES";
  return (
    <Box>
      <TopShared heading={heading} page={page} />
      <Box className="service">
        <Grid
          container
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          {data.map((item, index) => {
            return (
              <Grid
                className="img-item"
                onClick={() => {
                  navigate(item.path);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                item
                xs={12}
                md={4}
                lg={2.4}
                sx={{
                  backgroundColor: "#1F4F76",
                  overflow: "hidden",
                  position: "relative",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                key={index}
              >
                <Box className="topVec">
                  <img src={topVec} alt="..." className="img1 " />
                  <img src={topVec3} alt="..." className="img2" />

                 {item.icon}
                  {/* <img src={topVec2} alt="..."  className="small" /> */}
                </Box>
                <Box>
                  <img src={item.img} alt="..." className="standard" />
                </Box>
                <Box className="service-layer">
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "22px",
                      margin: "20px 0px",
                      padding: "20px 0px 0px",
                      display: "flex",
                      fontWeight: "500",
                      flexDirection: "column",
                    }}
                  >
                    <span> {item.text}</span>

                    {item.text2 ? <span> {item.text2}</span> : undefined}
                  </p>

                  <p className="serve-p">{item.p}</p>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}
