import { Box } from '@mui/material'
import React from 'react'
import ServiceProp from './components/ServiceProp'
import small from "../images/small-ssl.jpg";
import big from "../images/big-ssl.webp";
import icon1 from "../images/ui-icon-1.png";
import icon2 from "../images/ui-icon-2.png";
export default function SSL() {

    
  let heading = "SSL";
  let page = "SSL";
  const allData = {
    h1: "All Service",
    pspan1: "Web Development",
    pspan2: "Mobile Application",
    pspan3: "UI / UX",
    pspan4: "Cyber Security",
    pspan5: "SSL & Host",
    getFree: "Get a Free",
    solution: "Quick Solution ",
    it: "of IT Problem",
    contact: " Contact",
    product: "SSL Domain Services",
    p1: `

Secure Your Website with SSL Certificates
In today's digital landscape, ensuring the security and privacy of your website's data is paramount. SSL (Secure Socket Layer) certificates provide the encryption necessary to protect sensitive information, build trust with your users, and enhance your website's credibility. At [Your Company Name], we offer a range of SSL domain services to help you secure your website and protect your customers' data.`,
    h11: `  Nullamsed minon odio feugiat volutpat sit amet`,
    p2: `   Protect your website and your users' data with our comprehensive SSL domain services. Contact us today to learn more about how we can help you enhance your website's security and build trust with your audience.
            `,
    h22: " Why Choose Us?",
    bigImage: big,
    smallImage: small,
    icon11: icon1,
    icon22: icon2,
    lorem2: "We offer a wide range of SSL certificates and services to meet the  needs of your business.",
    lorem1: "Enhance user trust and confidence by securing your website with a reputable SSL certificate.",

    service: "User Trust",
    IT: "Comprehensive Solutions",
    ourfew: "Our Few Benefits",
    fewP: "  Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec eros id magna hendrerit sagittis.",
    id: "Namnec Eros Id Magna Hendrerit",
    id2:"Expertise: Our team has extensive experience in SSL certificate management and web security, ensuring your website is protected with the highest standards.",
    id3:"Comprehensive Solutions: We offer a wide range of SSL certificates and services to meet the specific needs of your website and business.",
    id4:"User Trust: Enhance user trust and confidence by securing your website with a reputable SSL certificate.",
//     id5:"Seamless Integration: Our hassle-free installation and configuration services ensure your SSL certificates are set up correctly and efficiently.",
    title1: "What is an SSL certificate and why do I need it?",
    details1: `An SSL (Secure Socket Layer) certificate encrypts data transferred between your website and its users, ensuring privacy and security. It's essential for protecting sensitive information and building user trust.`,
    title2: "How do I get an SSL certificate for my website?",
    details2: ` You can obtain an SSL certificate through a trusted Certificate Authority (CA). We offer SSL certificate issuance, installation, and management services to ensure your website is secure.`,
  };
  return (
 <Box>

    <ServiceProp  allData={allData} page={page} heading={heading} />

 </Box>
  )
}
