import { Box } from "@mui/material";
import React from "react";
import ServiceProp from "./components/ServiceProp";
import small from "../images/ui-small image.png";
import big from "../images/ui-big-image.png";
import icon1 from "../images/ui-icon-1.png";
import icon2 from "../images/ui-icon-2.png";
export default function UIUX() {
  let heading = "UI / UX";
  let page = "UI / UX";
  const allData = {
    h1: "All Service",
    pspan1: "Web Development",
    pspan2: "Mobile Application",
    pspan3: "UI / UX",
    pspan4: "Cyber Security",
    pspan5: "SSL & Host",
    getFree: "Get a Free",
    solution: "Quick Solution ",
    it: "of IT Problem",
    contact: " Contact",
    product: "UI/UX Design Services",
    bigImage: big,
    smallImage: small,
    icon11: icon1,
    icon22: icon2,
    p1: `
Enhance Your Digital Experience
In today’s fast-paced digital world, a seamless and engaging user experience is crucial for the success of any application or website. At [Your Company Name], we specialize in creating user interfaces (UI) and user experiences (UX) that are not only visually appealing but also intuitive and user-friendly. Our UI/UX design services ensure that your digital product stands out in the competitive market, providing your users with an enjoyable and memorable experience.`,
    h11: `  Nullamsed minon odio feugiat volutpat sit amet`,
    p2: ` Transform your digital presence with our comprehensive UI/UX design services. Contact us today to learn more about how we can help you create a user experience that delights and engages.
            `,
    h22: " Why Choose Us?",

    lorem2: "We work closely with you throughout the design process.",
    lorem1: "Our team of experienced designers and researchers are passionate ",

    service: "Expert Team",
    IT: "Collaborative Process",
    ourfew: "Our Few Benefits",
    fewP: "  Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec eros id magna hendrerit sagittis.",
    id: "Namnec Eros Id Magna Hendrerit",
    id2: "Expert Team: Our team of experienced designers and researchers are passionate about creating exceptional digital experiences.",
    id3: "User-Centered Approach: We put your users at the heart of our design process, ensuring that their needs and preferences drive every decision.",
    id4: "Cutting-Edge Tools: We utilize the latest tools and technologies to deliver high-quality UI/UX designs that exceed expectations.",
    title1: " What is the difference between UI and UX design?",
    details1: `  UI (User Interface) design focuses on the visual elements of a product, like layout and buttons, while UX (User Experience) design is about the overall feel and usability of the product. UI is about looks; UX is about function and user satisfaction.`,
    title2: "Why is UI/UX design important?",
    details2: ` Good UI/UX design enhances user satisfaction, boosts efficiency, improves brand perception, provides a competitive edge, and increases conversion rates. It ensures your product is visually appealing and easy to use, leading to better user engagement and retention.`,
  };
  return (
    <Box>
      <ServiceProp allData={allData} page={page} heading={heading} />
    </Box>
  );
}
