import { Outlet } from "react-router-dom";
import '@fontsource/barlow'; // Defaults to weight 400
// or
import '@fontsource/barlow/400.css'; // Weight 400
import '@fontsource/barlow/700.css'; // Weight 700 for bold text
import "./css/style.css";
import Header from "./pages/components/Header";
import Footer from "./pages/components/Footer";
function App() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer/>
    </div>
  );
}

export default App;
