import { Box } from "@mui/material";
import React from "react";
import ServiceProp from "./components/ServiceProp";
import small from "../images/cyber-smallmage.png";
import big from "../images/cyber-bigImage.png";
import icon1 from "../images/cyber-icon-1.png";
import icon2 from "../images/cyber-icon-2.png";
export default function CyberSecurity() {
  let heading = "Cyber Security";
  let page = "CYBER SECURITY";
  const allData = {
    h1: "All Service",
    pspan1: "Web Development",
    pspan2: "Mobile Application",
    pspan3: "UI / UX",
    pspan4: "Cyber Security",
    pspan5: "SSL & Host",
    getFree: "Get a Free",
    solution: "Quick Solution ",
    it: "of IT Problem",
    contact: " Contact",
    product: "Cybersecurity Services",
    p1: `

Protect Your Digital Assets with Advanced Security Solutions
In an increasingly connected world, cybersecurity is paramount to protect your digital assets from ever-evolving threats. At [Your Company Name], we offer comprehensive cybersecurity services designed to safeguard your business from cyber attacks, data breaches, and other security threats. Our expert team utilizes the latest technologies and best practices to ensure your systems and data remain secure and compliant.`,
    h11: `  Nullamsed minon odio feugiat volutpat sit amet`,
    p2: ` Secure your business with our comprehensive cybersecurity services. Contact us today to learn more about how we can help you protect your systems and data from cyber threats.
            `,
    h22: " Why Choose Us?",
    bigImage: big,
    smallImage: small,
    icon11: icon1,
    icon22: icon2,

    lorem1:
      "We leverage the latest technologies and tools to provide robust and effective security solutions.",
    lorem2:
      "We work closely with you to understand your unique security challenges ",

    service: "Client-Centric Service",
    IT: "Cutting-Edge Technology",
    ourfew: "Our Few Benefits",
    fewP: "  Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec eros id magna hendrerit sagittis.",
    id: "Namnec Eros Id Magna Hendrerit",
    id2: "Expert Team: Our team of certified cybersecurity professionals has extensive experience in protecting organizations of all sizes from cyber threats.",
    id3: "Comprehensive Solutions: We offer a full range of cybersecurity services tailored to meet your specific needs and industry requirements.",
    id4: "Proactive Approach: Our proactive approach to cybersecurity ensures that potential threats are identified and addressed before they can cause harm.",
    title1: "What is cybersecurity and why is it important?",
    details1: ` Cybersecurity involves protecting systems, networks, and data from digital attacks. It's crucial because it helps prevent data breaches, protects sensitive information, and ensures the integrity and availability of your systems.`,
    title2: " How can I protect my business from cyber threats?",
    details2: ` To protect your business, implement strong passwords, use multi-factor authentication, regularly update software, conduct security audits, and educate employees about phishing and other threats. Professional cybersecurity services can provide comprehensive protection.`,
  };
  return (
    <Box>
      <ServiceProp allData={allData} page={page} heading={heading} />
    </Box>
  );
}
