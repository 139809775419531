import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SettingsIcon from "@mui/icons-material/Settings";
import { Box, CardMedia, Grid } from "@mui/material";
import React, { useEffect } from "react";

import img1 from "../images/img-one.png";
import img2 from "../images/img-2.png";
import img3 from "../images/img-3.png";
import svg from "../images/svg.png";

import image from "../images/about-shape-1-1.png";

import { FaCircle } from "react-icons/fa6";

export default function AboutUs() {
  useEffect(() => {
    let number = document.querySelector(".p");
    let section = document.querySelector("#about");
    // let circleSection = document.querySelector(".circle-numbers");
    // let circles = document.querySelectorAll(".circle-numbers  .circle-p");
    // let start2 = false;
    let start = false;

    window.onscroll = function () {
      if (window.scrollY >= section.offsetTop) {
        if (start == false) {
          startCount(number);
        }

        start = true;
      }

      // if (window.scrollY >= section.offsetTop - 50) {
      //   if (start2 == false) {
      //     circles.forEach((item) => startCount2(item));
      //   }
      //   start2 = true;
      // }
    };

    function startCount(element) {
      let goal = element.dataset.goal;
      console.log(goal);

      let count = setInterval(() => {
        element.textContent++;
        if (element.textContent == goal) {
          clearInterval(count);
        }
      }, 500 / goal);
    }

    // function startCount2(element) {
    //   let goal2 = element.dataset.goal;
    //   console.log(goal2);

    //   let count2 = setInterval(() => {
    //     element.textContent++;
    //     if (element.textContent == goal2) {
    //       clearInterval(count2);
    //     }
    //   }, 500 / goal2);
    // }
  });

  let style = {};
  let boxes = [
    { number: "24", text: "Projects" },
    { number: "500", text: "Happy Customers" },
    { number: "1000", text: "Programming lines" },
  ];
  let ul = [
    { text: "Customer Focus" },
    { text: "Innovation as we Staying ahead of technological trends" },
    { text: "Conduct our business with honesty and transparency" },
  ];
  return (
    <Box className="about-us" id="about" sx={{ marginTop: "20px" }}>
      <img src={image} alt="..." className="img" />
      <h1>ABOUT US</h1>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "80px",
        }}
      >
        <Grid item xs={12} lg={6}>
          <Grid
            container
            sx={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              padding: "20px",
              position: "relative",
            }}
          >
            <Grid
              item
              sx={{
                position: { xs: "absolute", lg: "static" },
                left: { xs: "40px", sm: "270px", lg: "40px" },
              }}
            >
              <CardMedia
                image={img2}
                component="img"
                sx={{
                  width: { xs: "30px", lg: "65px" },
                  height: { xs: "90%", lg: "85%" },
                }}
              />
            </Grid>
            <Grid item>
              <CardMedia image={img1} component="img" sx={{ width: "100%" }} />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              transform: { xs: "translateY(-55px)", md: "translateY(-100px)" },
              padding: "20px",
            }}
          >
            <Grid item>
              <CardMedia
                image={img3}
                component="img"
                sx={{ width: { xs: "100%", md: "100%" } }}
              />
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: { xs: "space-between", md: "flex-start" },
                transform: {
                  xs: "translate(60%, -48%)",
                  md: "translateX(10%)",
                },
                marginTop: "10px",
              }}
            >
              <Box
                className="svg-box"
                sx={{
                  padding: "10px 20px",
                  width: "160px",

                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "185px",
                  marginLeft: "-5px",
                  marginBottom: "5px",
                  transform: "translateX(-20px)",
                }}
              >
                <Box
                  position="relative"
                  display="inline-block"
                  width={100}
                  height={100}
                  sx={{ marginBottom: "10px" }}
                >
                  <LightbulbIcon
                    style={{
                      fontSize: 60,
                      color: "white",
                      position: "absolute",
                      top: 10,
                      left: 20,
                    }}
                  />
                  <SettingsIcon
                    style={{
                      fontSize: 30,
                      color: "white",
                      position: "absolute",
                      top: 10,
                      right: 15,
                    }}
                  />
                  <SettingsIcon
                    style={{
                      fontSize: 20,
                      color: "white",
                      position: "absolute",
                      bottom: 10,
                      left: 20,
                    }}
                  />
                </Box>
                <p
                  className="p"
                  data-goal="20"
                  style={{
                    fontSize: "35px",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  20
                </p>
                <p style={{ fontSize: "16px", color: "white" }}>
                  Years of Experience
                </p>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={4} sx={{ padding: "20px" }}>
          <h2 style={{ fontSize: "44px" }}>
            Provide the Best Easy Solution for Your IT Problem
          </h2>
          <p
            style={{
              color: "#686A6F",
              fontSize: "16px",
              lineHeight: "25px",
              margin: "30px 0px",
            }}
          >
            We are a group of technical experts, gathered to provide our
            services at competitive prices and with higher quality. Where we
            developed and created many programs that helped their owners to
            perform their tasks easier, faster and more efficiently.{" "}
          </p>

          <Box
            className="circle-numbers"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {boxes.map((item, index) => {
              return (
                <Box
                  className="circle"
                  key={index}
                  sx={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="circle-p"
                    data-goal={item.number}
                    style={{
                      fontSize: "35px",
                      color: "white",
                      fontWeight: "500",
                    }}
                  >
                    {item.number}
                  </p>
                  <p style={{ fontSize: "16px", color: "white" }}>
                    {item.text}
                  </p>
                </Box>
              );
            })}
          </Box>
          <ul>
            {ul.map((item, index) => {
              return (
                <li
                  style={{
                    fontSize: "18px",
                    color: "#1F4F76",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Box>
                    {" "}
                    <FaCircle />
                  </Box>{" "}
                  <Box>
                    <span>{item.text}</span>
                  </Box>
                </li>
              );
            })}
          </ul>
        </Grid>
      </Grid>
    </Box>
  );
}
