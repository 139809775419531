import { Box } from "@mui/material";
import React from "react";
import IndexBoxes from "./IndexBoxes";
import AboutUs from "./AboutUs";
import Consult from "./Consult";
import Why from "./Why";
import Services from "./Services";
import IndexSlider from "./components/IndexSlider";
import WhatSaid from "./components/WhatSaid";
import Loading from "./components/Loading";
import LineAnimate from "./components/LineAnimate";

export default function Home() {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <Loading />
      <IndexSlider />
      <Services />
      {/* <IndexBoxes /> */}
      <AboutUs />
      <Why />
      <Consult />
      <LineAnimate />
      <WhatSaid />
    </Box>
  );
}
