import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, Grid } from "swiper/modules";

import img1 from "../../images/slider-1-1.jpg";
import img2 from "../../images/slider-1-2.jpg";
import img3 from "../../images/slider-1-1.jpg";

import { Link, useNavigate } from "react-router-dom";
import { Box, CardMedia } from "@mui/material";
import traingle from "../../images/Polygon 1.png";
import leftborder from "../../images/Polygon 2.png";



export default function IndexSlider() {

  
  let navigate = useNavigate();
  
  let data = [
    {
      p:" Efficient solution, Exponential Results",
      text1:" IT SOFTWARE SOULTION &",
      text2:"TECHNOLOGY",
      link:"Contact US",
      img : img1
    },
    {
      p:" Efficient solution, Exponential Results",
      text1:" IT SOFTWARE SOULTION &",
      text2:"TECHNOLOGY",
      link:"Contact US",
      img : img2

    },
    {
      p:" Efficient solution, Exponential Results",
      text1:" IT SOFTWARE SOULTION &",
      text2:"TECHNOLOGY",
      link:"Contact US",
      img : img3

    },
  ]

  
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  // const onAutoplayTimeLeft = (s, time, progress) => {
  //     progressCircle.current.style.setProperty("--progress", 1 - progress);
  //     progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  // };
  return (
    <Box
      sx={{ marginBottom: "80px", height: { xs: "80vh", md: "100vh" } }}
      className="index-slide"
    >
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // onAutoplayTimeLeft={onAutoplayTimeLeft}
        className="mySwiper"
      >
{
  data.map((item, index)=>{


    return(
      <SwiperSlide
      style={{ position: "relative", height: { xs: "80vh", md: "100vh" } }}
    >
      <CardMedia
        image={item.img}
        component="img"
        sx={{ width: "100%", height: { xs: "80vh", md: "100vh" } }}
        className="index-image"
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          transform: "translateY(10%)",
          top: "0px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: "18px",
            backgroundColor: "black",
            padding: "10px",
            // marginTop:"86px"

          }}
          id="p"
        >
          {item.p}
        </p>
        <h1
          className="h1"
          id="h1"
          style={{ color: "white", fontSize: "70px", textAlign: "center" }}
        >
         {item.text1}<br />
          {item.text2}
        </h1>
        <a
          className="contact-link"
          href="/contact"
          
         data-aos-anchor-placement="center-center"
          onClick={() => {
            navigate("/contact");
          }}
        >
          {item.link}
        </a>
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }} >
        <CardMedia
          image={leftborder}
          component="img"
          sx={{ position: "absolute", right: "0px", bottom: "0px", width: { md: "300", lg: "400px" } }}
        />
        <CardMedia
          image={traingle}
          component="img"
          sx={{ position: "absolute", right: "0px", bottom: "0px", width: { md: "220", lg: "300px" } }}
        />
      </Box>
    </SwiperSlide>
    )
  })
}
        {/* <SwiperSlide
          style={{ position: "relative", height: { xs: "80vh", md: "100vh" } }}
        >
          <CardMedia
            image={img2}
            component="img"
            sx={{ width: "100%", height: { xs: "80vh", md: "100vh" } }}
          />
          <Box
            className="index-layer"
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "#419FEC",
                fontSize: "16px",
                backgroundColor: "black",
                padding: "10px",
                marginTop: "86px"

              }}
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1500"
            >
              Efficient solution, Exponential Results
            </p>
            <h1
              className="h1"
              style={{ color: "white", fontSize: "70px", textAlign: "center" }}
              data-aos="fade-left"
              data-aos-anchor="#example-anchor"
              data-aos-offset="500"
              data-aos-duration="500"
            >
              IT SOFTWARE SOULTION &<br />
              TECHNOLOGY
            </h1>
            <a
              data-aos="fade-up"
              data-aos-anchor-placement="center-center"
              className="contact-link"
              href="/contact"

              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact US
            </a>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <img
              src={leftborder}
              alt="..."
              style={{ position: "absolute", right: "0px", bottom: "0px", width: "400px" }}
            />
            <img
              src={traingle}
              alt="..."
              style={{ position: "absolute", right: "0px", bottom: "0px", width: "300px" }}
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide
          style={{ position: "relative", height: { xs: "80vh", md: "100vh" } }}
        >
          <CardMedia
            image={img3}
            component="img"
            sx={{ width: "100%", height: { xs: "80vh", md: "100vh" } }}
          />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "#419FEC",
                fontSize: "16px",
                backgroundColor: "black",
                padding: "10px",
                marginTop: "86px"

              }}
            >
              Efficient solution, Exponential Results
            </p>
            <h1
              className="h1"
              style={{ color: "white", fontSize: "70px", textAlign: "center" }}
            >
              IT SOFTWARE SOULTION &<br />
              TECHNOLOGY
            </h1>
            <a
             data-aos="fade-up"
             data-aos-anchor-placement="center-center"
        
       
              className="contact-link"
              href="/contact"
           
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact US
            </a>
          </Box>
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <img
              src={leftborder}
              alt="..."
              style={{ position: "absolute", right: "0px", bottom: "0px", width: "400px" }}
            />
            <img
              src={traingle}
              alt="..."
              style={{ position: "absolute", right: "0px", bottom: "0px", width: "300px" }}
            />
          </Box>
        </SwiperSlide> */}
      </Swiper>
     
    </Box>
  );
}
