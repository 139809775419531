import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { IoClose } from "react-icons/io5";
import { FaHome } from "react-icons/fa";

import { MdOutlineRoundaboutRight } from "react-icons/md";
import { FaServicestack } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import { FaPhoneVolume } from "react-icons/fa6";

export default function Drawer(props) {
  let navigate = useNavigate();

  let links = [
    { text: "Home", path: "/", icon: <FaHome style={{ marginTop: "5px" }} /> },
    {
      text: "About",
      path: "/#about",
      icon: <MdOutlineRoundaboutRight style={{ marginTop: "5px" }} />,
    },
    {
      text: "Services",
      path: "/#services",
      icon: <FaServicestack style={{ marginTop: "5px" }} />,
    },
    {
      text: "Why Correct",
      path: "/#why-correct",
      icon: <FaQuestionCircle style={{ marginTop: "5px" }} />,
    },
    {
      text: "Contact",
      path: "contact",
      icon: <FaPhoneVolume style={{ marginTop: "5px" }} />,
    },
  ];

  return (
    <Box
      className="drawer"
      sx={{
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: "0px",

        zIndex: "100",
        transition: ".5s",

        left: props.drawer ? "0%" : "-150%",
      }}
    >
      <Box
        sx={{
          width: "280px",
          height: "100vh",
          position: "fixed",
          left: props.left ? "0px" : "-100%",
          zIndex: "100",
          transition: "0.5s",
        }}
        className="content"
      >
        <Grid container>
          <Grid
            item
            xs={8}
            sx={{ display: "flex", alignItems: "center", padding: "10px" }}
            onClick={() => {
              navigate("/");
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              setTimeout(() => {
                props.setDrawer(false);
              }, 200);
            }}
          >
            <img src={logo} alt="..." />
          </Grid>

          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "30px",
            }}
          >
            <IoClose
              style={{
                color: "white",
                fontSize: "25px",
                fontWeight: "500",
                cursor: "pointer",
              }}
              onClick={() => {
                props.setLeft(false);
                setTimeout(() => {
                  props.setDrawer(false);
                }, 500);
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {links.map((item, index) => {
              return (
                <HashLink
                  to={item.path}
                  style={{
                    color: "white",
                    fontSize: "18px",
                    textDecoration: "none",
                    fontWeight: "400",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  smooth={true}
                  duration={500}
                  onClick={() => {
                    props.setLeft(false);
                    setTimeout(() => {
                      props.setDrawer(false);
                    }, 500);
                  }}
                >
                  {item.icon ? <span>{item.icon}</span> : undefined}
                  <span> {item.text}</span>
                </HashLink>
              );
            })}
          </Grid>
        </Grid>
        <p
          style={{
            padding: "10px",
            fontSize: "18px",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <IoMailSharp /> <span>info@correct.ws</span>
        </p>
        <p
          style={{
            padding: "10px",
            fontSize: "18px",
            color: "white",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <FaPhone /> <span>0222877709</span>
        </p>
      </Box>
    </Box>
  );
}
