import { Box } from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from "react";

import { ThreeDots } from "react-loader-spinner";

export default function Loading() {

    useEffect(() => {
        AOS.init({
          duration: 1000, // Animation duration in milliseconds
          easing: 'ease-in-out', // Easing function for animations
          once: true, // Whether animation should happen only once - while scrolling down
          mirror: false, // Whether elements should animate out while scrolling past them
        });
        setTimeout(()=>{
            document.getElementById("loading").setAttribute("data-aos","fade-in")

        } ,  2000)
        setTimeout(()=>{
            document.getElementById("loading").style.display= "none"
            
        } ,  2900)
      }, []);
    
  return (
    <Box
      id="loading"
      className="loading"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "100",
        position: "fixed",
        top: "0px",
        left: "0px",
        height: "100vh",
        width: "100vw",
      }}
    >
      <ThreeDots
        visible={true}
        height="90"
        width="90"
        color="white"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Box>
  );
}
