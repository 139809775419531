import { Box } from "@mui/material";
import React from "react";
import ServiceProp from "./components/ServiceProp";

import small from "../images/smallweb.png";
import big from "../images/bigweb.png";
import icon1 from "../images/icon-1-web.png";
import icon2 from "../images/icon-2-web.png";

export default function WebDevelopment() {
  let heading = "Web Development";
  let page = "WEB DEVELOPMENT";
  const allData = {
    h1: "All Service",
    pspan1: "Web Development",
    pspan2: "Mobile Application",
    pspan3: "UI / UX",
    pspan4: "Cyber Security",
    pspan5: "SSL & Host",
    bigImage: big,
    smallImage: small,
    icon11: icon1,
    icon22: icon2,
    getFree: "Get a Free",
    solution: "Quick Solution ",
    it: "of IT Problem",
    contact: " Contact",
    product: " Web Development Services",
    p1: `
Crafting Exceptional Digital Experiences
In the digital age, a well-designed website is crucial for establishing a strong online presence and engaging your audience. At [Your Company Name], we specialize in creating dynamic, user-friendly websites that are tailored to meet your unique business needs. Our web development services encompass everything from initial concept to deployment and maintenance, ensuring your website not only looks great but performs flawlessly.
            `,
    h22: "Why Choose Us?",

    lorem1:
      "Our team of skilled developers has extensive experience in building high-quality websites ",
    lorem2:
      "We offer ongoing support and maintenance to ensure your website continues ",

    service: "Experienced Team",
    IT: "Dedicated Support",
    ourfew: "Our Few Benefits",
    fewP: "  Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec eros id magna hendrerit sagittis.",
    id: "Namnec Eros Id Magna Hendrerit",
    id2: "Client-Focused Approach: We work closely with you to understand your business needs and goals, ensuring the final product aligns with your vision.",
    id3: "Latest Technologies: We leverage the latest technologies and best practices to build modern, secure, and high-performing websites.",
    id4: "Comprehensive Services: From initial concept to deployment and maintenance, we provide end-to-end web development services to meet all your needs.",
    id5: "Dedicated Support: We offer ongoing support and maintenance to ensure your website continues to perform optimally and remains up-to-date with the latest trends.",
    title1: " Can you help with updating an existing website?",
    details1: ` Yes, we offer services to update and improve existing websites. Whether you need design tweaks, new features, or performance enhancements, we can help.`,
    title2: " How long does it take to develop a website?",
    details2: ` The timeline for developing a website varies based on its complexity and features. A simple website can take 4-6 weeks, while more complex sites with custom functionality may take 3-6 months or longer. We provide a detailed timeline after understanding your specific project requirements.`,
  };
  return (
    <Box>
      <ServiceProp allData={allData} page={page} heading={heading} />
    </Box>
  );
}
