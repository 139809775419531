import { Box } from "@mui/material";
import React from "react";
import ServiceProp from "./components/ServiceProp";
import small from "../images/mobile-small-image.png";
import big from "../images/mobile-big-image.png";
import icon1 from "../images/mobile-icon-1.png";
import icon2 from "../images/mobile-icon-2.png";
export default function MobileApp() {
  let heading = "Mobile Applications";
  let page = "MOBILE APPLICATIONS";
  const allData = {
    h1: "All Service",
    pspan1: "Web Development",
    pspan2: "Mobile Application",
    pspan3: "UI / UX",
    pspan4: "Cyber Security",
    pspan5: "SSL & Host",
    getFree: "Get a Free",
    solution: "Quick Solution ",
    it: "of IT Problem",
    contact: " Contact",
    product: " Mobile Application Development Services",
    bigImage: big,
    smallImage: small,
    icon11: icon1,
    icon22: icon2,
    p1: `

Transforming Ideas into Engaging Mobile Experiences
In an era where mobile technology is integral to daily life, having a powerful and user-friendly mobile application is essential for businesses to engage with their customers and enhance their digital presence. At [Your Company Name], we specialize in developing high-quality mobile applications tailored to your unique business needs. Our end-to-end mobile application development services cover everything from initial concept to deployment and maintenance, ensuring your app not only meets but exceeds user expectations.`,
    h11: `  Nullamsed minon odio feugiat volutpat sit amet`,
    p2: `    Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec
            eros id magna hendrerit sagittis. Nullam sed mi non odio feugiat
            volutpat sit amet nec elit. Maecenas id hendrerit ipsum.
            `,
    h22: "Why Choose US?",

    lorem1: "From initial concept to deployment and ongoing maintenance.",
    lorem2:
      " We offer continuous support and maintenance to ensure your mobile app remains up-to-date and performs optimally.",

    service: "Comprehensive Services",
    IT: "Dedicated Support",
    ourfew: "Our Few Benefits",
    fewP: "  Nam vel lacus eu nisl bibendum accumsan vitae vitae nibh. Nam nec eros id magna hendrerit sagittis.",
    id: "Namnec Eros Id Magna Hendrerit",
    id2: "Experienced Team: Our team of skilled developers and designers has extensive experience in creating high-quality mobile applications that deliver exceptional user experiences.",
    id3: "Client-Centered Approach: We work closely with you to understand your business goals and create a mobile app that aligns with your vision.",
    id4: "Latest Technologies: We utilize the latest tools and technologies to build modern, secure, and high-performing mobile applications.",
    title1: "How long does it take to develop a mobile application?",
    details1: ` The time it takes to develop a mobile application depends on various factors, including the complexity of the app, the number of features, the design requirements, and the platforms being targeted (iOS, Android, or both). Typically, a basic app can take around 3 to 6 months to develop, while more complex applications may take 6 to 12 months or longer. Our team will work with you to establish a detailed timeline based on your specific project requirements.`,
    title2: "What is the cost of developing a mobile application?",
    details2: ` The cost of developing a mobile application varies widely based on several factors, such as the complexity of the app, the number of features, the design intricacies, and the platforms (iOS, Android, or both). A simple app with basic features can range from $10,000 to $50,000, while more complex apps with advanced functionality can cost between $50,000 and $150,000 or more. During our initial consultation, we will discuss your project in detail and provide a customized quote based on your specific needs and budget.`,
  };
  return (
    <Box>
      <ServiceProp allData={allData} page={page} heading={heading} />
    </Box>
  );
}
